import React from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/seo"
import { Button } from "react-bootstrap"
import ReactMarkdown from "react-markdown"

const Career = () => {
  const data = useStaticQuery(graphql`
    query CareerQuery {
      allStrapiCareers {
        edges {
          node {
            careers {
              buttonLink
              buttonText
              description
              hiringProcess
              id
              title
            }
            benefits {
              description
              title
            }
            gallery {
              primary {
                formats {
                  small {
                    publicURL
                  }
                }
              }
              secondary {
                formats {
                  small {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const { careers, benefits, gallery } = data.allStrapiCareers.edges[0].node

  return (
    <StyledWrapper>
      <Layout>
        <SEO
          title="Careers"
          description="Want to participate in creating transformation through design and technology? Join us. If you
                        don't see a job opening for you below, write to us, we may still be interested to hear from you."
          path="/career"
        />
        <div className="container">
          <div className="row career-container">
            <div className="col-sm-6 col-12">
              <p className="careers-heading mb-sm-5 mb-3">{careers.title}</p>
              <p className="pre-line careers-description">
                {careers.description}
              </p>
              <div className="d-flex my-5">
                <ReactMarkdown className="hiring-process">
                  {careers.hiringProcess}
                </ReactMarkdown>
                <div className="hiring-process">
                  <a className="btn-link" href={careers.buttonLink} target="_blank" rel="noopener nofollow">
                    <Button className="submit-form ml-4 p-2" type="submit">
                      {careers.buttonText}
                    </Button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-12 d-flex flex-column justify-content-center mb-2">
              <div className="benefits-section mx-auto">
                <p className="title">{benefits.title}</p>
                <p className="pre-line description">{benefits.description}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid px-sm-0 px-4">
          <div className="row">
            {gallery.primary.map(image => (
              <div className="col-sm-6 col-12 px-0 mb-2 mb-sm-5">
                <img
                  className="img-fluid gallery-images"
                  src={image.formats.small.publicURL}
                  alt=""
                />
              </div>
            ))}
          </div>
          <div className="row">
            {gallery.secondary.map(image => (
              <div className="col-sm-6 col-12 px-0 mb-2 mb-sm-0">
                <img
                  className="img-fluid gallery-images"
                  src={image.formats.small.publicURL}
                  alt=""
                />
              </div>
            ))}
          </div>
        </div>
      </Layout>
    </StyledWrapper>
  )
}

export default Career

const StyledWrapper = styled.div`
  .career-container{
    padding-top:100px;
  }
 
  .hiring-process {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .btn-link::before {
      border-bottom: none;
      background-color: white !important;
    }

    p {
      margin: 0;
    }
  }

  .gallery-images {
    height: 400px;
    width: 100%;
    object-fit: cover;
  }

  .careers-heading {
    font-size: 36px;
    line-height: 127.5%;
  }

  .careers-description {
    font-size: 20px;
    line-height: 32px;
  }

  .benefits-section {
    .title {
      font-size: 24px;
      line-height: 127.5%;
    }

    .description {
      font-size: 18px;
      line-height: 170.5%;
      font-weight: lighter;
    }
  }

  @media(min-width:991px){
    .career-container {
      padding-top: 200px;
    }

    .careers-heading {
      font-size: 48px;
    }

    .benefits-section {
      .title {
        font-size: 28px;
      }
  
      .description {
        font-size: 22px;
        font-weight: lighter;
      }
    }
  }

  
`
